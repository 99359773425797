<!--商品列表 ALL-->
<template>
  <div class="page-good-list">

    <Breadcrumb
      :data="breadList"
    ></Breadcrumb>

    <div class="common-gap"></div>

    <div class="bottom-content">

      <el-row :gutter="30" class="good-list">
        <el-col class="mb40" :key="item.id" :span="6" v-for="item in goodList">
          <GoodCard :item="item"></GoodCard>
        </el-col>
      </el-row>

      <el-empty class="el-empty" v-if="goodList.length===0" description="No Data"></el-empty>

      <div style="text-align: center">
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="[8,20,40]"
          layout="prev, pager, next,sizes"
          :total="total">
        </el-pagination>
      </div>


    </div>

  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import GoodCard from "@/components/GoodCard";

import { getAllProductClaasify, queryProductByClassify, showAllFunction } from "@/api/good";
import XEUtils from "xe-utils";

export default {
  components: { Breadcrumb, GoodCard },
  data() {
    return {
      breadList: [],
      productClassifyId: -1,
      pageNum: 1,
      pageSize: 8,
      total: 0,
      goodList: []
    };
  },
  methods: {
    async pageChange(page) {
      this.pageNum = page;
      await this.queryData();
    },
    async sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.pageNum = 1;
      await this.queryData();
    },
    async queryData() {
      const res = await queryProductByClassify(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          productClassifyId: this.productClassifyId
        }
      );
      console.log("res", res);
      this.goodList = res.data.list;
      this.total = res.data.total;
    },
    async getMenu() {
      const res = await this.$store.dispatch("GET_TREE_LIST_BY_CLASS_ID", this.$route.params.id);
      if (res && res.nodes) {
        const len = res.nodes.length;
        this.breadList = res.nodes.map((item, index) => {
          if (len === index + 1) {
            return {
              name: item.classifyName
            };
          } else {
            return {
              name: item.classifyName,
              to: {
                name: "goodList",
                params: {
                  id: item.id
                }
              }
            };
          }

        });

        console.log("breadList", this.breadList);

      }
    }
  },

  async created() {
    console.log("检测到参数:", this.$route);
    this.productClassifyId = this.$route.params.id;
    await this.queryData();
    await this.getMenu();

  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.productClassifyId = this.$route.params.id;
        //重新加载一次created或者mounted钩子中的渲染的数据
        this.queryData();
        this.getMenu();
      }
    }
  }
};
</script>

<style lang="less" scoped>

.page-good-list {
  min-height: calc(100vh - 12.5rem);
  display: flex;
  flex-direction: column;
}

.mb40 {
  margin-bottom: 40px;
}

.prod-group {
  margin-bottom: 20px;
}

.el-empty {
  min-height: 500px;
}

.bottom-content {
  background: var(--main-color-bg-gray);
  padding: 36px 75px 75px;
  flex: 1;
}
</style>
